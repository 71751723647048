<template>
  <div ref="chartRefs"></div>
</template>

<script>
// echarts组件
import * as echarts from "echarts";
// 引入接口
import { lineReq } from "@/apis/hdpi";
export default {
  data() {
    return {
      date: [], //"12-08",日期
      browse_data: [], //  "": 0,浏览数量
      share_data: [], //   "": 0,//分享数量
      order_data: [], //   "": 0//订单数量
    };
  },
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    // 
    async getData() {
      let data = { activity_id: this.$route.query.activity_id };
      let res = await lineReq(data);
      this.date = []
      this.browse_data = []
      this.share_data = []
      this.order_data = []
      res.data.data.forEach((v) => {
        this.date.push(v.date);
        this.browse_data.push(v.browse_data);
        this.share_data.push(v.share_data);
        this.order_data.push(v.order_data);
      });
      console.log(this.date)
      this.echartsInits(
        this.date,
        this.browse_data,
        this.share_data,
        this.order_data
      );
    },
    echartsInits(date, browse_data, share_data, order_data) {
      let myChart = echarts.init(this.$refs.chartRefs);
      // 指定图表的配置项和数据
      let option = {
        title: {
          left: "center",
          text: "订单走势分析图",
          textStyle: {
            fontSize: 15,
            color: "#ccc",
          },
        },
        tooltip: {},
        legend: {
          bottom: 20,
          data: ["浏览量", "转发量", "订单数量"],
          textStyle: {
            color: "#fff",
            fontWeight: "normal",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: date,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "浏览量",
            type: "line",
            data: browse_data,
          },
          {
            name: "分享量",
            type: "line",
            data: share_data,
          },
          {
            name: "订单数量",
            type: "line",
            data: order_data,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.clear();
      myChart.setOption(option);
      //适配
      window.addEventListener("resize", myChart.resize);
    },
  },
};
</script>

<style lang="scss" scoped>
.chartRefs {
  height: 100%;
}
</style>