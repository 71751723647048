<template>
  <div class="table_box"> 
    <el-table
      ref="wgzp"
      :data="tableData"
      height="calc(100% - 32px)"
      class="elTable"
      @cell-mouse-enter="mouseEnter()"
      @cell-mouse-leave="mouseLeave()"
    >
      <el-table-column prop="nickname" :show-overflow-tooltip="true" width="150px" label="昵称"></el-table-column>
      <el-table-column prop="people_count" label="团队数量"></el-table-column>
      <el-table-column prop="browse_frequency" label="浏览量"></el-table-column>
      <el-table-column prop="share_frequency" label="分享数量"></el-table-column>
    </el-table>
  </div>
  
</template>

<script>
/**
 * @date 2022-03-11
 * @Description: 滚动表格
 */
// 获取接口
import { fansRankingReq } from "@/apis/hdpi";
let timer = null
export default {
  name: "",
  data() {
    return {
      interval: "",
      tableData:'',
      // 鼠标是否进入
      enter: false,
      // 表单是否能滚动
      isScorll: true,
    };
  },
  created() {
    this.getconfig()
    
  },
  methods: {
    scroll() {
      if(this.tableData.length < 6){
        return
      }
      let than = this
      timer = window.setInterval(function() {
        let maxHeight = than.$refs.wgzp.$el.querySelectorAll(".el-table__body")[0].offsetHeight;
        let clientHeight = than.$refs.wgzp.bodyWrapper.clientHeight;
        
        if (
          Math.abs(
            than.$refs.wgzp.bodyWrapper.scrollTop - (maxHeight - clientHeight)
          ) < 5
        ) {
          //预留5像素误差
          than.$refs.wgzp.bodyWrapper.scrollTop = 0;
        } else {
          than.$refs.wgzp.bodyWrapper.scrollTop += 1; 
        }
      }, 40);
    },
    mouseEnter() {
      window.clearInterval(timer)
      timer = null
    },
    /**
     * @desc 鼠标出去表格触发
     **/
    mouseLeave() {
      window.clearInterval(timer)
      timer = null
      this.scroll();
    },
    async getconfig() {
      let than = this
      
      let data = { activity_id: this.$route.query.activity_id };
      let res = await fansRankingReq(data);
      window.clearInterval(timer)
      timer = null
      than.tableData = res.data.data
      this.$nextTick(()=>{
        
        than.scroll()
      })
      
      
    },
  },
  mounted() {
    //请求后台接口获取列表数据
    // this.getconfig();
 
  },
  beforeDestroy() {
    clearInterval(timer);
  },
};
</script>

<style lang="scss">
/*美化滚动条样式*/
.img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}
.table_box{
  height: 100%;
  box-sizing: border-box;
}
.border-box-content{
  border-radius: 20px;
  overflow: hidden;
}
::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
}
::-webkit-scrollbar-thumb:vertical {
  height: 0px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 0px;
}
::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 6px;
}
/*表格内容样式调整，可删除*/
.elTable {
  th.el-table__cell {
    color: #fff;
    background-color: #071031;
  }
  .el-table__row:nth-of-type(odd) {
    background-color: rgba(16, 24, 78, 0.9);
  }
  .el-table__row:nth-of-type(even) {
    background-color: rgba(9, 13, 56, 0.9);
  }
}

.el-table__body-wrapper {
  background-color: rgba(10, 14, 64, 0.9);
}
.elTable td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
.elTable::before {
  height: 0;
}
.el-table{
  color: #fff;
}
.el-table__row:hover .cell{
  color: #606266; 
}
</style>
