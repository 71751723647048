//引入工具函数 request.js
import request from '@/utils/request';

// 获取员工排行
export const staffRankingReq = (data) => {
    return request({
        url: 'edu/Groupinglarge/queryEmployeeRanking',
        method: 'post',
        data
    })
}
// 获取园区排行
export const gardenRankingReq = (data) => {
    return request({
        url: 'edu/Groupinglarge/querySchoolRanking',
        method: 'post',
        data
    })
}
// 获取粉丝排行
export const fansRankingReq = (data) => {
    return request({
        url: 'edu/Groupinglarge/queryFansRanking',
        method: 'post',
        data
    })
}
// 获取活动信息成功
export const activitygReq = (data) => {
    return request({
        url: 'edu/Groupinglarge/queryActivityInformation',
        method: 'post',
        data
    })
}
// 获取实时订单数据
export const realTimeReq = (data) => {
    return request({
        url: 'edu/Groupinglarge/queryOrderList',
        method: 'post',
        data
    })
}
// 订单走势分析
export const lineReq = (data) => {
    return request({
        url: 'edu/Groupinglarge/queryOrderTrend',
        method: 'post',
        data
    })
}
// 饼图1
export const pieoneReq = (data) => {
    return request({
        url: 'edu/Groupinglarge/querySchoolProportion',
        method: 'post',
        data
    })
}
// 饼图2
export const pietwoReq = (data) => {
    return request({
        url: 'edu/Groupinglarge/queryNewOldProportion',
        method: 'post',
        data
    })
}
// 获取语言订单
export const languageReq = (data) => {
    return request({
        url: 'edu/Groupinglarge/queryRealTimeOrderList',
        method: 'post',
        data
    })
}