<template>
  <ScreenAdapter :width="'1920'">
    <!-- 顶部 -->
    <div class="head df jcsa">
      <div class="df jcsa" style="width: 30%">
        <p style="font-size: 8px; width: 80px" @click="fullScreen()">
          按F11全屏退出
        </p>
        <p class="f1">
          <img
            @click="showmute"
            class="img"
            v-show="showPrise"
            src="../../assets/imgs/play.png"
            alt=""
          />
          <img
            @click="showplay"
            class="img"
            v-show="showEagleMap"
            src="../../assets/imgs/stop.png"
            alt=""
          />
        </p>
      </div>
      <h3 style="width: 30%">数据大屏</h3>
      <p style="width: 30%">{{ nowTime }}</p>
    </div>
    <div class="content df jcsa mt10">
      <!-- 左 -->
      <div class="right" style="width: 30%; height: 100%">
        <!-- 左-上 -->
        <div
          class="mt10 data_box"
          style="width: 100%; height: 30vh"
          ref="box"
        >
          <!-- <ranking-staff ref="staff" /> -->
          <h4 class="headline">员工排行榜</h4>
          <RankingStaff  ref="staff"/>
          <div class="boxfoot"></div>
        </div>
        <!-- 左-中 -->
        <div
          class="mt10 data_box"
          style="width: 100%; height: 30vh"
          ref="box"
        >
          <h4 class="headline">园区排行</h4>
          <ranking-garden ref="garden"/>
          <div class="boxfoot"></div>
        </div>
        <!-- 左-下 -->
        <div
          class="mt10 data_box"
          style="width: 100%; height: 30vh"
          ref="box"
        >
          <h4 class="headline">粉丝排行</h4>
          <ranking-fans ref="fans"/>
          <div class="boxfoot"></div>
        </div>
      </div>
      <!-- 中 -->
      <div class="centre" style="width: 36%">
        <div>
          <div style="40vh">
            <div class="mt10 data_box" style="height: 15vh">
              <p class="txt">{{ ctivityinfo.activity_name }}</p>
              <div class="boxfoot"></div>
            </div>
          </div>
          <div class="mt10 data_box" style="height: 15vh; width: 100%">
            <div class="df jcsa" style="width: 100%; height: 48%">
              <div class="statistics">
                <number :numAttr="{num:ctivityinfo.team_count,big:.6,styleColer:'#F9696B'}"/>
              </div>
              <div class="statistics">
                <number :numAttr="{num:ctivityinfo.yesterday_count,big:.6,styleColer:'#F9696B'}"/>
              </div>
              <div class="statistics">
                <number :numAttr="{num:ctivityinfo.today_count,big:.6,styleColer:'#F9696B'}"/>
              </div>
              <div class="statistics">
                <number :numAttr="{num:ctivityinfo.share_frequency,big:.6,styleColer:'#F9696B'}"/>
              </div>
              <div class="statistics">
                <number :numAttr="{num:ctivityinfo.browse_frequency,big:.6,styleColer:'#F9696B'}"/>
              </div>
            </div>
            <div class="mt10 df jcsa" style="width: 100%">
              <p class="center">订单总数</p>
              <p class="center">昨日订单</p>
              <p class="center">今日订单</p>
              <p class="center">分享次数</p>
              <p class="center">浏览次数</p>
            </div>
            <div class="boxfoot"></div>
          </div>
        </div>
        <div class="mt10 data_box" style="height: 60vh">
          <real-time :tableData="realTableData" />
          <div class="boxfoot"></div>
        </div>
      </div>
      <!-- 右 echarts -->
      <div class="leght mt10 " style="width: 30%">
        <!-- 第一份图 -->
        <div class="echartsArr data_box">
          <line-diagram ref="chartRefs" style="width: 100%; height: 100%"/>
          <div class="boxfoot"></div>
        </div>

        <!-- 第二份图 -->
        <div class="mt10 echartsArr data_box">
          <pie-chart ref="pietRef" style="width: 100%; height: 100%"
        />
          <div class="boxfoot"></div>
        </div>

        <!-- 第三份图 -->
        <div class="mt10 echartsArr data_box">
          <pie-two ref="pieTwo" style="width: 100%; height: 100%"/>
          <div class="boxfoot"></div>
        </div>
      </div>
    </div>
  </ScreenAdapter>
</template>

<script>
// 列表组件
// 适配组件
import ScreenAdapter from "@/components/ScreenAdapter";
// 轮播表组件
import RankingStaff from "@/components/RankingStaff";
import RankingFans from "@/components/RankingFans";
import RankingGarden from "@/components/RankingGarden";
import RealTime from "@/components/RealTime";
// echarts组件
import LineDiagram from "@/components/LineDiagram";
import PieChart from "@/components/PieChart";
import PieTwo from "@/components/PieTwo";
import number from "@/components/number";
// 引入js文件
import { timeNow } from "../../utils/utils";
// 引入接口
import { activitygReq, languageReq , realTimeReq } from "@/apis/hdpi";
export default {
  components: {
    RankingStaff,
    RankingFans,
    RankingGarden,
    RealTime,
    // echarts组件
    // 线图
    LineDiagram,
    PieChart,
    PieTwo,
    // 适配组件
    ScreenAdapter,
    number,
    audio:null
  },
  data() {
    return {
      showPrise: false,
      showEagleMap: true,
      voicelist: "1",
      nowTime: "",
      // 活动信息
      ctivityinfo: "",
      // "activity_id": 1,
      //   "activity_name": "活动名称",//活动名称
      //   "share_frequency": 11,//分享数量
      //   "browse_frequency": 5,//浏览数量
      //   "team_count": 2,//订单总数量
      //   "yesterday_count": 0,//昨天订单s数量
      //   "today_count": 0//今日订单数量,
      realTableData:'',//
      frequencyOfSpeech:'',
      resetVoice:null,//订单数量是否更新
      voiceDialing:false, //订单语音是否播放完毕

    };
  },
  watch:{
    frequencyOfSpeech(newVal,oldVal){
      console.log(newVal,'最新次数')
      
    }
  },
  created() {
    this.getData();
    this.getDatalist()
  },
  mounted() {
    // 执行时间函数
    console.log(this.$refs.staff)
    this.getAll();
    // 请求语音接口
    this.playAudio();
    
    window.setInterval(() => {
      setTimeout(() => {
        this.playAudio();
      }, 0);
    }, 10000);
  },
  // vue生命周期
  beforeDestroy() {
    /* 关闭页面销毁所有定时器 */
    clearInterval(this.tiemEq);
  },

  methods: {
    getAll() {
      this.tiemEq = setInterval(() => {
        /* 时间 */
        this.nowTime = timeNow();
      }, 1000);
    },
    // 关闭播放
    showplay() {
      this.showEagleMap = false;
      this.showPrise = true;
      console.log("关闭");
      console.log(this.showPrise);
      console.log(this.showEagleMap);
    },
    // 静音
    showmute() {
      this.showEagleMap = true;
      this.showPrise = false;
      console.log("打开");
      console.log(this.showPrise);
      console.log(this.showEagleMap);
    },
    // 语音接口
    async playAudio(i) {
      console.log(i,'请求次数')
      let than = this
      let data = { activity_id: this.$route.query.activity_id };
      let res = await languageReq(data);
      this.frequencyOfSpeech = res.data.data.count
      console.log(this.frequencyOfSpeech,'cishu')
      if(this.frequencyOfSpeech !== 0){
        this.$refs.staff.getconfig()
        this.$refs.garden.getconfig()
        this.$refs.fans.getconfig()
        this.$refs.chartRefs.getData()
        this.$refs.pietRef.getData()
        this.$refs.pieTwo.getData()
        this.getData()
        this.getDatalist()
      }else{
        return false
      }
      this.voiceDialing = false
      this.audioPlay(this.frequencyOfSpeech)

      // audio.pause();
    },
    //time 播放次数
    audioPlay(time){
      let than = this
      let url = `https://tyy-oss.oss-cn-huhehaote.aliyuncs.com/voice/order2.mp3`;
      than.audio = new Audio();
      than.audio.src = url;
      // var duration = '';
      // if(!this.voiceDialing && than.audio){
      //   than.audio.pause();
      // }
      if (!this.showEagleMap) {
        than.audio.pause();
      } else {
        than.audio.play(); //播放音频
        // setTimeout(() => {
        //   than.audio.play(); //播放音频
        //   than.audio.loop = true
        // }, 50 );
        // than.audio.addEventListener("timeupdate", () => {
        //   duration = than.audio.duration*1000
        //   console.log(time*duration)
        //   than.resetVoice = setTimeout(()=>{
        //     than.audio.pause();
        //     this.voiceDialing = true
        //   },time*duration)
        // });
      }
        
      
      
      
      //Uncaught (in promise) DOMException: play() failed because the user didn't interact with the document first. 报这个错的时候可以加click
      //audio.click();
      return
      
      
    },
    // 发送请求
    async getData() {
      let data = { activity_id: this.$route.query.activity_id };

      // this.$route.query.id
      let res = await activitygReq(data);
      this.ctivityinfo = res.data.data;
    },
    async getDatalist() {
      let data = { activity_id: this.$route.query.activity_id };
      let res1 = await realTimeReq(data);
      this.realTableData = res1.data.data;
    },

    // 点击进入全屏
    fullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #fff;
}
.data_box{
  border: 1px solid rgba(25,186,139,.17);
  overflow: hidden;
  position: relative;
}
.boxfoot{
  position: absolute;
  bottom: 1px;
  width: 100%;
  left: 0;
  height: 12px;
}
.data_box::before{
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  border-left: 2px solid #02a6b5;
  border-top: 2px solid #02a6b5;
  top: 0;
}
.data_box::after{
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  border-right: 2px solid #02a6b5;
  border-top: 2px solid #02a6b5;
  right: 0;
  top: 0;
}

.boxfoot::before{
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  border-left: 2px solid #02a6b5;
  border-bottom: 2px solid #02a6b5;
  left: 0;
}
.boxfoot::after{
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  border-right: 2px solid #02a6b5;
  border-bottom: 2px solid #02a6b5;
  right: 0;
  bottom: 0;
}
.center {
  color: #fff;
}
.img {
  background-color: #fff;
  width: 20px;
  height: 20px;
}
.head {
  text-align: center;
  height: 5vh;
  line-height: 5vh;
  color: #fff;
  background: url(../../assets/imgs/head_bg.png) no-repeat 50%;
  background-size: 100% 100%;
  position: relative;
  z-index: 100;
  background-color: #020425;
}
.txt {
  text-indent: 2em;
  text-align: left;
  width: 100%;
  height: 100%;
  line-height: 100px;
  font-size: 3vh;
  color: #fff;
}
.statistics {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  color: #fff;
}
.center {
  text-align: center;
}
// echarts图
.echartsArr {
  width: 100%;
  height: 30vh;
}
table {
  border-spacing: 0px;
  border-collapse: collapse;
}
</style>