/* 工具函数层 */
import axios from 'axios'

//默认请求地址  千万不要有空格
axios.defaults.baseURL = 'https://www.tuoyuyun.cn'

//默认超时事件
axios.defaults.timeout = 10000

//请求拦截器 作用:携带统一参数
axios.interceptors.request.use(config => {
   
    return config
}, err => {
    return Promise.reject(err)
})

//响应拦截器
axios.interceptors.response.use(response => {
    //response 响应信息对象
    return response
}, err => {
    return Promise.reject(err)
})

//导出配置好的axios
export default axios