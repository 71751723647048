<template>
  <div style="background-color:transparent;" ref="pietRef"></div>
</template>
  
  <script>
// echarts组件
import * as echarts from "echarts";
// 引入接口
import { pieoneReq } from "@/apis/hdpi";

export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let data = { activity_id: this.$route.query.activity_id };
      let res = await pieoneReq(data);
      this.list = res.data.data;
      this.echartsInits();
    
    },

    echartsInits() {
      let myChart = echarts.init(this.$refs.pietRef);
      let arr = [];
      this.list.forEach((v) => {
        arr.push({
          value: v.today_count,
          name: v.school_name,
        });
      });
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "园区占比",
          subtext: "",
          left: "center",
          y: 15,
          textStyle: {
            fontSize: 24,
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "80%",
            label: {
              show: false,
            },
            data: arr,//数组
            y: 30,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      //适配
      window.addEventListener("resize", myChart.resize);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.aa {
  text-align: center;
}

</style>