<template>
  <div style="background-color:transparent;" ref="pieTwo"></div>
</template>
    
    <script>
// echarts组件
import * as echarts from "echarts";
// 引入接口
import { pietwoReq } from "@/apis/hdpi";

export default {
  data() {
    return {};
  },
  mounted() {
    this.getData();
  },
 
  methods: {
    async getData() {
      //   
      let data = { activity_id: this.$route.query.activity_id};
      let res = await pietwoReq(data);
      let value1 = res.data.data.old_count;//老生
      let value2 = res.data.data.new_count;//新生
      // 赋值
      this.echartsInits(value1, value2);
    },
    echartsInits(value1, value2) {
      let myChart = echarts.init(this.$refs.pieTwo);

      // 指定图表的配置项和数据
      let option = {
        title: {
          left: "center",
          text: "老生新生占比",
          x: "center",
          y: 15,
          textStyle: {
            color: "#fff",
            fontSize: 24
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
          top: "25%",
          textStyle: {
            color: "#fff",
            fontSize: 18
          },
        },
        series: [
          {
            type: "pie",
            radius: "80%",
            label: {
              show: false,
            },
            y: 30,
            data: [
              { value: value2, name: "新生" },
              { value: value1, name: "老生" },
            ],
            textStyle: {
              fontSize: 15,
              color: "#fff",
            },

            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      //适配
      window.addEventListener("resize", myChart.resize);
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.aa {
  text-align: center;
}
</style>