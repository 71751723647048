<template>
  <el-table
    ref="wgzp"
    :data="tableData"
    height="100%"
    class="elTable"

  >
    <el-table-column label="头像">
      <template slot-scope="scope">
        <el-image class="img" :src="scope.row.head_img" />
      </template>
    </el-table-column>
    <el-table-column prop="nickname" :show-overflow-tooltip="true" width="120px" label="微信昵称"></el-table-column>
    <el-table-column prop="phone" label="电话"></el-table-column>
    <el-table-column prop="staff_name" :show-overflow-tooltip="true" width="150px" label="员工"></el-table-column>
    <el-table-column prop="pay_time" label="支付日期"></el-table-column>
  </el-table>
</template>

<script>
/**
 * @date 2022-03-11
 * @Description: 滚动表格
 */
// 获取接口
import { realTimeReq } from "@/apis/hdpi";
export default {
  name: "",
  props:['tableData'],
  data() {
    return {
      // tableData: [
      //   {
      //     // "nickname": "包包",//昵称
      //     //   "head_img"://头像 "https://wx.qlogo.cn/mmopen/vi_32/ibosNicTyoqnYrIPtI2O1A1BAKckVNwymoURcGRVGrzPwY9JrD0MFSBCnAjoDMRmzJAuvGAQfytAfpCxTFkwicoxw/132",
      //     //   "phone": "15982306659",//电话
      //     //   "staff_name": "员工姓名",//员工名称
      //     //   "add_time": "2022-11-28"//创建时间
      //   },
      // ],
    };
  },
  created() {},
  methods: {
    scroll() {
      let maxHeight =
        this.$refs.wgzp.$el.querySelectorAll(".el-table__body")[0].offsetHeight;
      let clientHeight = this.$refs.wgzp.bodyWrapper.clientHeight;
      if (
        Math.abs(
          this.$refs.wgzp.bodyWrapper.scrollTop - (maxHeight - clientHeight)
        ) < 5
      ) {
        //预留5像素误差
        this.$refs.wgzp.bodyWrapper.scrollTop = 0;
      } else {
        this.$refs.wgzp.bodyWrapper.scrollTop += 32; //32是每一行表格的高度，每秒滚一行
      }
    },
    
    // async getDatalist() {
    //   let data = { activity_id: this.$route.query.activity_id };
    //   let res = await realTimeReq(data);
    //   this.tableData = res.data.data;
    // },
  },
  mounted() {
    //请求后台接口获取列表数据
    // this.getDatalist();
 
  },
  beforeDestroy() {
  },
};
</script>

<style>
/*美化滚动条样式*/
.img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}
::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
}
::-webkit-scrollbar-thumb:vertical {
  height: 0px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 0px;
}
::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 6px;
}
/*表格内容样式调整，可删除*/
.elTable {
  
}
.elTable th.el-table__cell {
    color: #fff;
    background-color: rgba(12, 22, 57, 0.9);
  }
.elTable .el-table__row:nth-of-type(odd) {
  background-color: rgba(16, 24, 78, 0.9);
}
.elTable .el-table__row:nth-of-type(even) {
  background-color: rgba(9, 13, 56, 0.9);
}

.el-table__body-wrapper {
  background-color: rgba(10, 14, 64, 0.9);
}
.elTable td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
.elTable::before {
  height: 0;
}
.el-table{
  color: #fff;
}
.el-table{
  font-size: 18px !important;
}
.el-table__row:hover .cell{
  color: #606266; 
}
</style>
